
import { Breadcrumb, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SummeryTable from "../IssuesTable/SummeryTable";

const WeeklySummery = () => {
  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/reports"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/reports"} className="BreadcrumbLink">
                    Reports
                  </Link>
                ),
              },
              {
                title: "Summery",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white">
        <Col span={24}><SummeryTable  /></Col>
      </Row>
    </>
  );
};

export default WeeklySummery;
