import {
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  message,
  Row,
  Select,
  Table,
  TableProps,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setLoading, userData } from "../store/slices/user-slice";
import { useGetTaskBoardList } from "../Services/RQDashboardService";
import {
  addStatusChangeLog,
  getEllipsisText,
  statusconvertor,
} from "../utils/Util";
import HTTPSCalls from "../Services/HTTPCalls";
import { ArrowLeftOutlined, ReloadOutlined } from "@ant-design/icons";
import TableSkeleton from "./dashboard/Common/TableSkeleton";

const ViewAllTaskbord = () => {
  // const [ReloadOutlinedIcon, setReloadOutlinedIcon] = useState(false)
  const user = useSelector(userData);
  const { isPending, data: taskList } = useGetTaskBoardList(
    {
      start: 0,
      length: 17000,
      assigneeId: user?.id,
      sort: 1,
      isDone: 1,
      isBacklog: 1,
      isPriority: 1,
    },
    user?.id
  );
  const [selectedPriority, setSelectedPriority] = useState(4);

  const dataList = useMemo(() => {
    return taskList?.result?.map((task: any, index: number) => ({
      ...task,
      key: index,
      serial: index + 1,
      project: task?.project[0],
      tStatus: { label: statusconvertor(task?.tStatus), value: task?.tStatus },
    }));
  }, [taskList]);
  const navigate = useNavigate();
  // console.log("dataList", dataList);

  const filteredData = dataList?.filter((item: any) => {
    if (selectedPriority === 4) {
      return true; // Show all items if 'All' is selected
    } else {
      return item?.priority === selectedPriority;
    }
  });

  const statusOption = useMemo(
    () => [
      { label: "Backlog", value: 0 },
      { label: "Todo", value: 1 },
      { label: "Need to Discuss", value: 2 },
      { label: "In Progress", value: 3 },
      { label: "Developed", value: 7 },
      { label: "Duplicate", value: 8 },
      { label: "UAT", value: 4 },
      { label: "Testing", value: 5 },
      { label: "Done", value: 6 },
    ],
    []
  );
  const priorityOptions = [
    { label: "All", value: 4 },
    { label: "High", value: 2 },
    { label: "Medium", value: 1 },
    { label: "Low", value: 0 },
  ];

  // Handle change function Api
  const handleChange = async (record: any, itm: any) => {
    setLoading(false);
    try {
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.updateStatus + "?",

        {},
        {
          tid: record?.id,
          tstatus: itm?.value,
        }
      );
      // console.log("Update response:", res);
      // Show success notification
      if (res?.status) {
        message.success({
          content: "Task Status updated successfully.",
          duration: 2, // You can adjust the duration if needed
        });
        let task: any = {
          ...res?.result,
          UKey: res?.result?.uKey,
          _id: res?.result?.id,
        };

        await addStatusChangeLog(
          task,
          task?.tStatus,
          record?.tStatus?.value,
          2,
          record?.project
        );
        return res;
      } else {
        message.error({
          content: res?.message,
        });
      }
    } catch (error) {
      console.error("Error updating task status:", error);

      // Show error notification
      message.error({
        content: "Error Updating TaskBoard Status.",
        duration: 2, // You can adjust the duration if needed
      });
    } finally {
      setLoading(true);
    }
  };
  const priorityconvertor = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            High
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            Medium
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            Low
          </span>
        );
      default:
        return null;
    }
  };
  const columns:any = useMemo(
    () => [
      {
        title: "S. No.",
        dataIndex: "serial",
        key: "serial",
        width: 45,
        fixed: 'left',
        render: (text: string, record: any, index: number) => (
          <div className="pl-4">{text}</div>
        ),
      },
      {
        title: (
          <Typography.Text
            ellipsis
            className="ml-2"
            style={{ fontWeight: 600 }}
          >
            UKey
          </Typography.Text>
        ),
        dataIndex: "uKey",
        key: "uKey",
        width: 70,
        fixed: 'left',
        // showSorterTooltip: false,
        sorter: (a: any, b: any) => a.uKey - b.uKey,
        render: (uKey: any, record: any) => (
          <Link
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          to={`/task/${record?.id}`}
        >
          <Button
            size="small"
            className="resume-btn-sm mt-1 ml-2"
            style={{
              // backgroundColor: "rgb(204, 229, 255)",
              color: "rgb(16, 124, 16)",
              backgroundColor: "rgb(223, 246, 221)",
              width: "6vw",
              border: "none",
            }}
            // onClick={() => navigate("/task/" + record?.id)}
          >
           
           
            <Tooltip title={uKey} ><Typography.Paragraph style={{  color: "rgb(16, 124, 16)",}} ellipsis>{uKey}</Typography.Paragraph></Tooltip>
             
         
          </Button>
          </Link>
        ),
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: 280,
        // showSorterTooltip: false,
        sorter: (a: any, b: any) => a.title - b.title,
        render: (title: any) => (
          <Tooltip title={getEllipsisText(title, 150)}>
            <Typography.Paragraph ellipsis>{title} </Typography.Paragraph>
          </Tooltip>
        ),
 
      },
      
      {
        title: <span style={{ fontWeight: 600 }}>Priority</span>,
        dataIndex: "priority",
        key: "priority",
        // showSorterTooltip: false,
        sorter: (a: any, b: any) => a.priority - b.priority,
        render: (itm: any) => priorityconvertor(itm),
        width: 80,
      },
      {
        title: <span style={{ fontWeight: 600 }}>Status</span>,
        dataIndex: "tStatus",
        key: "tStatus",
     
        width: 100 ,   // showSorterTooltip: false,
        sorter: (a: any, b: any) => a.tStatus - b.tStatus,
        render: (itm: any, record: any) => (
          <Select
            labelInValue
            className="taskbord"
            size="small"
            style={{ width: "180px" }}
            options={statusOption}
            defaultValue={itm}
            onSelect={(val) => {
              handleChange(record, val);
            }}
          />
        ),
        
      },
    ],
    [statusOption]
  );
  const onSearch = (value: string) => {
    // console.log("search:", value);
  };
  const breadcrumbItems = [
    {
      title: (
        <Link to={"/dashboard"} className="BreadcrumbLink">
          Home
        </Link>
      ),
    },
    {
      title: "View all",
    },
  ];
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });

    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <>
      <Row justify={"space-between"} className="mb-2">
        <Col span={2}>
          <Button type="link" onClick={() => navigate("/")}>
            <ArrowLeftOutlined />
          </Button>
        </Col>
        <Col
          className="pl-15"
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          <Breadcrumb items={breadcrumbItems} />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"end"} className="mb-2">
            <Col span={24}>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      colorTextPlaceholder: "#95a5a6",
                      fontSize: 14,
                      colorPrimaryHover: "none",
                      // controlHeight: 32,
                      fontSizeIcon: 10,
                      // borderRadius: 4,
                      paddingSM: 4,
                      paddingXS: 4,
                      paddingXXS: 4,
                      lineWidth: 1,
                      borderRadius: 4,
                      borderRadiusLG: 4,
                      borderRadiusSM: 4,
                      borderRadiusXS: 4,
                      controlPaddingHorizontal: 4,
                      controlPaddingHorizontalSM: 4,
                      controlHeight: 26,
                      controlHeightLG: 26,

                      algorithm: true,
                      fontWeightStrong: 600,
                    },
                  },
                }}
              >
                <Row align={"middle"}>
                  <Col span={23}>
                    <Typography.Text>Priority</Typography.Text>
                    <Select
                      className="capTask-custom-select"
                      style={{
                        width: 180,
                        margin: "0px 20px",
                      }}
                      defaultValue={"All"}
                      options={priorityOptions}
                      onChange={(item: any) => setSelectedPriority(item)}
                      showSearch
                      onSearch={onSearch}
                      optionFilterProp="label"
                    />
                  </Col>
                  <Col span={1} style={{ textAlign: "end" }}>
                    {/* <Button
                      style={{ height: 27 }}
                      onClick={() => {
                        setReloadOutlinedIcon((prevState: any) => ({
                          ...prevState,
                        }));
                      }}
                      icon={
                        <ReloadOutlined
                          style={{
                            color: "rgb(61, 153, 37)",
                            fontSize: "14px",
                          }}
                        />
                      }
                    /> */}
                  </Col>
                </Row>
              </ConfigProvider>
            </Col>
          </Row>
          {/* <Loader loading={isLoading} /> */}
        </Col>

        <Col span={24}>
          <Col span={24}>
            {isPending ? (
              <TableSkeleton
                columns={columns}
                // scroll={{ y: "75vh" }}
                scroll={{ x: 'max-content', y: '75vh'  }}
                style={{
                  scrollbarWidth: "none",
                  borderTop: "solid 1px #b2bec3",
                }}
              />
            ) : (
              <Table
                className="capTask-custom-table"
                dataSource={filteredData}
                columns={columns}
                onChange={onChangeTable}
                pagination={{
                  showTotal: (total: any, range: any) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOption,
                  position: ["bottomRight"],
                }}
                scroll={{ y: "75vh" }}
                style={{
                  scrollbarWidth: "none",
                  borderTop: "solid 1px #b2bec3",
                }}
                locale={{
                  emptyText: (
                    <img
                      style={{ paddingTop: "80px", width: 140 }}
                      // src={process.env.PUBLIC_URL + "/noTask.svg"}
                      src={process.env.PUBLIC_URL + "/img-antd.svg"}
                      alt="No Task"
                    />
                  ),
                }}
              />
            )}
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(ViewAllTaskbord);
