import {
  Avatar,
  Button,
  Table,
  TableColumnsType,
  TableProps,
  Tooltip,
  Typography,
} from "antd";
import {
  getEllipsisText,
  statusbackgroundgenerator,
  statusconvertor,
} from "../../utils/Util";
import AssigneeAvatar from "../../components/commoncomponents/AssigneeAvatar";
import React, { useState } from "react";
import "../../common/common.css";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { AssigneeAvatarSmall } from "../dashboard/Common/AssigneeAvatar";
function SummeryTable({ reportList, IsLoading }: any) {
  const navigate = useNavigate();
  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging?.current - 1) * paging?.pageSize);
      changeListParams("length", paging?.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  // Tabel
  const columns: TableColumnsType<any> = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "7%",
      fixed: "left",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
        title: "User Name",
        dataIndex: "serial",
        key: "serial",
        width: "20%",
        fixed: "left",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
        render: (text: string, record: any, index: number) => (
          <div className="pl-4">{text}</div>
        ),
      },
    {
      title: "Reason",
      dataIndex: "serial",
      key: "serial",
      width: "60%",
      fixed: "left",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },

    {
      title: <Typography.Text ellipsis>Last Activity Date</Typography.Text>,
      dataIndex: "updatedBy",
      key: "Last Updated on",
      width: 20,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
      render: (task, record) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.id)}
          ellipsis
        >
          {moment(record?.updatedBy?.date).isSame(moment(), "day")
            ? `Today ${moment(record?.updatedBy?.date).format("LT")}` // Show "Today" and time if updated today
            : moment(record?.updatedBy?.date).format("DD MMM YYYY")}
        </Typography.Text>
      ),
    },
  ];

  return (
    <div className="mt-2 mr-4 ml-4">
      {IsLoading ? (
        <TableSkeleton
          columns={columns}
          scroll={{ y: "75vh" }}
          style={{
            scrollbarWidth: "none",
            borderTop: "solid 1px #b2bec3",
          }}
        />
      ) : (
        <Table
          // scroll={{ y: "71vh" }}
          scroll={{ x: "max-content", y: "71vh" }}
          columns={columns}
          dataSource={reportList?.map((data: any, index: number) => ({
            serial: index + 1,
            key: index,
            ...data,
          }))}
          onChange={onChangeTable}
          pagination={{
            showTotal: (total: any, range: any) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams?.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          className="issuestabel capTask-custom-table"
          locale={{
            emptyText: (
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                  paddingTop: "47px",
                }}
              >
                {/* <NoData message={undefined} /> */}
                <img
                  style={{ width: 140, paddingBottom: 15 }}
                  // src={process.env.PUBLIC_URL + "/noTask.svg"}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
              </Typography.Title>
            ),
          }}
        />
      )}
    </div>
  );
}
export default SummeryTable;
