import React, { useEffect, useState } from 'react';
import { Button, Drawer, Flex, Form, Input, Modal, Row, message } from 'antd';
import { useIssue } from '../../Services/RQMastersService';
import { CloseOutlined } from '@ant-design/icons';
const AddEditIssue = (props: any) => {
    const { mutateAsync: AddEditIssue, isPending } = useIssue();
    const [open, setOpen] = useState(true);
    const [form] = Form.useForm();
     const drawerIssue = props?.data?.id ? "Edit Issue" : "Add Issue";
      // console.log(props.data, "data");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        form.setFieldsValue(props?.data);
    }, []);

    // const handleSubmit = async () => {
    //     try {
    //         setLoading(true);
    //         const values = await form.validateFields();
    //         const val = {
    //             ...values,
    //             id: props?.data?.id
    //         };
    //         const res = await AddEditIssue(val);
    //         if (res?.status) {
    //             message.success('Issue submitted successfully!');
    //             onCancel();
    //             setLoading(true);
    //         } else {
    //             message.error(res?.message || "Something went wrong.");
    //         }
    //     } catch (error) {
    //         console.error('Error occurred:', error);

    //     } finally {
    //         setLoading(false);
    //     }
    // }
    const handleSubmit = async () => {
      try {
          setLoading(true);
          const values = await form.validateFields();
          
          // Check if it is an edit or submit action
          const isEdit = !!props?.data?.id;
  
          const val = {
              ...values,
              id: props?.data?.id
          };
  
          const res = await AddEditIssue(val);
  
          if (res?.status) {
              // Show different messages based on whether it's an edit or a submit
              if (isEdit) {
                  message.success('Issue updated successfully!');
              } else {
                  message.success('Issue submitted successfully!');
              }
  
              onCancel();
              setLoading(false); // Stop loading here instead of resetting it again in finally
          } else {
              message.error(res?.message || "Something went wrong.");
          }
      } catch (error) {
          console.error('Error occurred:', error);
      } finally {
          setLoading(false);
      }
  };
  
    const onCancel = () => {
        form.resetFields();
        setOpen(false);
        setTimeout(() => {
            props?.onDismiss();
        }, 300);
    };
    const values = Form.useWatch([], form);
    const [submittable, setSubmittable] = React.useState<boolean>(false);
    React.useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
         setLoading(false)
    }, [form, values]);
    return (
      <>
        <Drawer
          
          title={drawerIssue}
          extra={
            <>
              <CloseOutlined className="cursor" onClick={onCancel} />
            </>
          }
          closeIcon={false}
          open={open}
          footer={
            <>
              <Row justify={"end"}>
                <Button
                  className="capTask-btn-light btn-m-sm"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-m-sm"
                  type="primary"
                  onClick={handleSubmit}
                  disabled={!submittable}
                  loading={loading}
                >
                  Save
                </Button>
              </Row>
            </>
          }
          width={500}
        >
          <Form
            form={form}
            labelAlign="left"
            layout="horizontal"
            labelCol={{ span: 5 }}
            requiredMark={false}
            initialValues={{ orderNo: 0 }}
            onFinish={() => message.success("Submit success!")}
            onFinishFailed={() => message.error("Submit failed!")}
            className="DrawerPadding"
          >
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[
                { required: true, message: "Please select Issue Name!" },
              ]}
            >
              <Input
                variant="borderless"
                className="borderLessInput"
                placeholder="Issue Name"
              />
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
};

export default AddEditIssue;
