import { Droppable } from "@hello-pangea/dnd";
import { DraggableItemListView } from "./DraggableItemListView";
import { Button, Col, Row, Typography, Collapse, Tag, Tooltip } from "antd"; // Import Collapse
import { GRID_VIEW } from "../../constants/commonConstants";
import DraggableItemGridView from "./DraggableItemGridView";
import "../../common/common.css";
import { PlusOutlined, TagsOutlined } from "@ant-design/icons";
import { getEllipsisText } from "../../utils/Util";
import { useEffect, useState } from "react";
import { dayjs } from "../../utils/dayjs";
import { Link } from "react-router-dom";
import AddEditSprint from "../../pages/CreateSprint/AddEditSprint";

const { Panel } = Collapse; // Destructure Collapse to get Panel

const DroppableColumn = (props: any) => {
  // const [show, setShow] = useState("");
  // const [sprintData, setSprintData] = useState<any>();
  const status = props?.status;
  const tasks = props?.tasks ? [...props?.tasks] : [];
  const [ellipsisLength, setEllipsisLength] = useState(13);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 1880) {
        setEllipsisLength(5);
      } else if (width > 1880 && width <= 1024) {
        setEllipsisLength(10);
      } else {
        setEllipsisLength(13);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const formatDatedaytomonth = (date: any) => {
    return dayjs(date).format("DD MMM YYYY");
  };

  const endDate = dayjs(props?.sprintData?.endDate);
  const currentDate = dayjs();
  const isExpired = currentDate.isAfter(endDate); // Check if the sprint is already expired
  const isNearExpiration = endDate.diff(currentDate, "day") <= 15 && !isExpired;
  const daysRemaining = endDate.diff(currentDate, "day");
  // const onEdit = (values: any) => {

  //   let val = {
  //     ...values,
  //     fromTo: [dayjs(props?.sprintData?.startDate), dayjs(props?.sprintData?.endDate)],
  
  //   };
  //   setSprintData({ ...sprintData, data: { ...val } });
  //   setShow("AddEditSprint");
  //   // setPayload({ ...payload, projectId: val?.value });
  // };
  return (
    <div
      style={{
        margin: "9px 5px",
        width: props?.viewMode === GRID_VIEW ? "70vh" : "auto",
        height: props?.viewMode === GRID_VIEW ? "72vh" : "auto",
        backgroundColor: "#F2F6FE",
        overflowY: props?.viewMode === GRID_VIEW ? "visible" : "auto",
        maxHeight: props?.viewMode === GRID_VIEW ? "100vh" : "50vh",
        borderRadius: 5,
      }}
    >
      {/* Render without Collapse for Grid View */}
      {props?.viewMode === GRID_VIEW ? (
        <div
          style={{
            borderRadius: 5,
            overflowX: "scroll",
            maxHeight: "96%",
            marginBottom: "12px",
            width:'246px'
          }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{
              position: "sticky",
              bottom: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: "#F2F6FE",
              padding: "8px",
            }}
          >
            <Col className="p-2">
              <TagsOutlined style={{ color: "#B0BEC5" }} />
              <Typography.Text
                
                className="resume-btn-sm"
                style={{
                  color: "#000",
                  border: "none",
                }}
              >
                {
                  props?.sprintData ? props?.sprintData?.name : status
                  
                }
              </Typography.Text>
              {props?.sprintData && (
                <span className="pl-3 dataFormet">
                  {dayjs(props?.sprintData?.startDate)?.format("DD MMM")} -{" "}
                  {formatDatedaytomonth(props?.sprintData?.endDate)}
                </span>
              )}
            </Col>
            <Col style={{ textAlign: "center" }}>
              <Button
                size="small"
                className="resume-btn-sm mr-1"
                shape="round"
                style={{
                  backgroundColor: "rgb(204, 229, 255)",
                  width: "2vw",
                  border: "none",
                }}
              >
                {tasks?.length}
              </Button>
            </Col>
          </Row>
          <Droppable droppableId={status}>
            {(droppableProvided, snapshot) => (
              <div
                ref={droppableProvided?.innerRef}
                {...droppableProvided?.droppableProps}
                className={snapshot?.isDraggingOver ? "isDraggingOver" : ""}
                style={{
                  borderRadius: 5,
                  padding: "10px 6px",
                  overflowY: "clip",
                  maxHeight: "100%",
                  marginBottom: "12px",
                }}
              >
                {tasks?.map(
                  (task: any, index: number) =>
                    task?.uKey && (
                      <DraggableItemGridView
                        task={task}
                        index={index}
                        key={task?.id}
                        page={props?.page}
                        sourceDroppableId={status}
                        showLoading={props?.showLoading}
                        onSelectDropDown={props?.onSelectDropDown}
                        onClick={props?.onClick}
                      />
                    )
                )}
                {droppableProvided?.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ) : (
        <Collapse
        className="CollapseIconHide"
          // defaultActiveKey={tasks?.length ? ["1"] : []}
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          bordered={false}
        >
          
          <Panel
            header={
              <Row justify="space-between" align="middle">
                <Col>
                  <TagsOutlined style={{ color: "#B0BEC5" }} />
                  {props?.isLoading ? null : (
                    <Typography.Text
                      className="resume-btn-sm"
                      style={{
                        color: "#000",
                        border: "none",
                      }}
                    >
                      {props?.sprintData
                        ? props?.sprintData?.name
                        : props?.status}
                    </Typography.Text>
                  )}
                  {props?.sprintData && (
                    <Tooltip
                      title={
                        isExpired
                          ? "Your Sprint date range has expired."
                          : isNearExpiration
                          ? `Your Sprint is nearing expiration. Only ${daysRemaining} day${
                              daysRemaining !== 1 ? "s" : ""
                            } left.`
                          : ""
                      }
                    >
                      <span
                        style={{
                          textDecoration: isExpired ? "line-through" : "none",
                          color: isExpired ? "#994a3a" : "dataFormet",
                        }}
                        className="pl-3 dataFormet"
                      >
                        {dayjs(props?.sprintData?.startDate)?.format(
                          "DD MMM YYYY"
                        )}{" "}
                        -{" "}
                        {dayjs(props?.sprintData?.endDate)?.format(
                          "DD MMM YYYY"
                        )}
                        {isNearExpiration && (
                          <span
                            style={{
                              color:
                                daysRemaining > 15
                                  ? "#bcb949"
                                  : daysRemaining > 10
                                  ? "#95bcff"
                                  : daysRemaining > 7
                                  ? "#aaa469"
                                  : daysRemaining > 1
                                  ? "#994a3a"
                                  : "#994a3a", // Final day color (1 day or less)
                              fontSize: "12px",
                              marginLeft: "15px",
                            }}
                          >
                            {daysRemaining === 0
                              ? "(Today Expires)"
                              : `(${daysRemaining} day${
                                  daysRemaining !== 1 ? "s" : ""
                                } left... ${
                                  daysRemaining === 1
                                    ? "(Expires in 12 hrs)"
                                    : ""
                                })`}
                          </span>
                        )}
                      </span>
                      {/* {isExpired && (
                        <Button
                          size="small"
                          type="link"
                          icon={<PlusOutlined />}
                          style={{
                            color: "#994a3a",
                            marginLeft: 8,
                          }}
                          // onClick={() => setIsCreateSprint(true)}
                          onClick={(record:any) => onEdit(record)}
                        >
                          Add Sprint
                        </Button>
                      )} */}
                    </Tooltip>
                  )}
                </Col>

                <Col style={{ textAlign: "center" }}>
                  <Button
                    size="small"
                    className="resume-btn-sm mr-1"
                    shape="round"
                    style={{
                      backgroundColor: "rgb(204, 229, 255)",
                      width: "40px",
                      border: "none",
                    }}
                  >
                    {tasks?.length}
                  </Button>
                </Col>
              </Row>
            }
            key="1"
          >
            <Droppable droppableId={status}>
              {(droppableProvided, snapshot) => (
                <div
                  ref={droppableProvided?.innerRef}
                  {...droppableProvided?.droppableProps}
                  className={snapshot?.isDraggingOver ? "isDraggingOver" : ""}
                  style={{
                    borderRadius: 5,
                    overflowY: "clip",
                    padding: "10px 6px",
                    maxHeight: "92%",
                    marginBottom: "12px",
                  }}
                >
                  {tasks?.map(
                    (task: any, index: number) =>
                      task?.uKey && (
                        <DraggableItemListView
                          task={task}
                          index={index}
                          key={task?.id}
                          page={props?.page}
                          sourceDroppableId={status}
                          showLoading={props?.showLoading}
                          onSelectDropDown={props?.onSelectDropDown}
                          onClick={props?.onClick}
                        />
                      )
                  )}
                  {droppableProvided?.placeholder}
                </div>
              )}
            </Droppable>
            <div
              style={{
                position: "sticky",
                bottom: -1,
                padding: "8px",
                backgroundColor: "rgb(237, 241, 249 , 1)",
                zIndex: 1,
                // overflowY: "scroll",
                cursor: "pointer",
                borderRadius: 5,
              }}
            ></div>
          </Panel>
        </Collapse>
      )}
      {/* {show && (
        <AddEditSprint
        onDismiss={() => {
          setShow("");
          setSprintData({ ...sprintData, data: sprintData });
        }}
        data={sprintData}
      />
      )} */}
    </div>
  );
};

export default DroppableColumn;
