import React, { useEffect, useMemo, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Spin,
  message,
  notification,
} from "antd";
import HTTPSCalls from "../Services/HTTPCalls";
import "../common/Scrollbar.css";
import "../common/Board.css";
import ActiviyLogs from "./dashboard/ActiviyLogs/ActiviyLogs";
import Toothbar from "../components/commoncomponents/Toothbar";
import { useSelector } from "react-redux";
import {
  boardViewMode as BVM,
  projectData,
  setBoardViewMode,
} from "../store/slices/project-slice";
import { GRID_VIEW, STATUS } from "../constants/commonConstants";
import DragableBoard from "../components/dragableBoard/DragableBoard";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  boardRefreshState,
  refreshBacklog,
  refreshBoard,
  refreshTaskDrawer,
} from "../store/slices/refresh-slice";
import { useDispatch } from "react-redux";
import { addSprintChangeLog, addStatusChangeLog } from "../utils/Util";
import { Link } from "react-router-dom";
import AddEditTask from "../common/AddEditTask";
import { useTaskListByBoard } from "../Services/RQBoardService";

const splittedpath = window.location.pathname.split("/");
const projecturlid = splittedpath[splittedpath.length - 2];
const projecturlname = splittedpath[splittedpath.length - 3];
export enum UserType {
  All,
  Assignee,
  AssignedBy,
}

const Board: React.FC = () => {
  const project = useSelector(projectData);
  const [taskSearchFilter, setTaskSearchFilter] = useState<{
    projectId: string;
    searchText: string | undefined | null;
    date:
      | {
          from: any;
          to: any;
        }
      | undefined
      | null;
    priority: number[] | undefined | null;
    searchIn: "" | "Assignee" | "AssignedBy" | undefined | null;
    teamMemberIds: string[] | undefined | null;
    sprintIds: string[] | undefined | null;
  }>({
    projectId: project?.id,
    date: undefined,
    priority: undefined,
    searchIn: undefined,
    sprintIds: undefined,
    teamMemberIds: undefined,
    searchText: undefined,
  });
  // console.log("taskSearchFiltertaskSearchFiltertaskSearchFilter = ",taskSearchFilter);
  const { data: taskData12, isPending: isTaskListPending } =
    useTaskListByBoard(taskSearchFilter);
  const taskList = useMemo(() => {
    if (!!taskData12?.message)
      message.error(
        `Error in Get Task List in Board => ${taskData12?.message}`
      );
    return taskData12?.result || [];
  }, [taskData12]);

  const [selectedRecord, setSelectedRecord] = useState<any>(undefined);
  const [taskData, setTaskData] = useState<any>();
  // const user = useSelector(userData);
  const boardRefresh = useSelector(boardRefreshState);
  const boardViewMode = useSelector(BVM);
  const dispatch = useDispatch();
  const [projectname, setProjectName] = useState(project?.name || "");
  const [projectid, setProjectId] = useState(() => {
    return projecturlid || project?.id || "";
  });
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [projectimage, setProjectImage] = useState("");
  const [titleimg, setTitleImg] = useState("");
  const [randomcolor, setRandomColor] = useState("");

  const [userfilterid, setUserFilterId] = useState("");
  // const [userfilterpid, setUserFilterpId] = useState("");
  // const [assignedby, setAssignedby] = useState("");
  const [defaultUser, setDefaultUser] = useState<UserType>(UserType.All);
  const [cardWidth, setCardWidth] = useState(window.innerWidth);
  const [statusFilter, setStatusFilter] = useState("0,1,2,3,4,5,7");
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [status, setstatus] = useState(-1);
  // const [localproject, setlocalporject] = useState([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectid, setSelectedId] = React.useState<string | null>(null);
  const [searchTaskData, setSearchTaskData] = useState<any>();
  // const [selectedAvatarId, setSelectedAvatarId] = useState<number | null>(null);
  const showLoading = (id: string) => {
    setOpen(true);
    setSelectedId(id);
  };
  const [loadData, setLoadData] = React.useState<boolean>(false);
  const [toDo, settoDo] = React.useState<any>([]);
  const [priority, setPriority] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setCardWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchTasksByStatus = async (assigneeid?: any, assignee?: any) => {
    try {
      setIsLoading(true);
      if (projectid) {
        const projectId = project?.id || projectid;
        const response = await HTTPSCalls.GET(
          `${HTTPSCalls.ENDPOINTS.task.getAllByStatus}?`,
          {
            fromDate: fromdate,
            todate: todate,
            priority: priority,
            taskSorting: 0,
            statusFilter: statusFilter,
            status: status,
            sprintId: projectId,
            start: 0,
            length: 15000,
            projectid: projectId,
            assigneeId: selectedAssignee,
            isDone: 1,
            Utype: defaultUser,
          }
        );
        if (response?.result && response?.status) {
          const newTaskData = {
            TODO: [],
            NEEDTODISCUSS: [],
            INPROGRESS: [],
            UAT: [],
            TESTING: [],
            DEVELOPED: [],
          };

          response?.result?.forEach((item: any) => {
            switch (item?.status) {
              case STATUS?.TODO:
                newTaskData.TODO = item?.tasks;
                settoDo(item?.tasks);
                break;
              case STATUS.NEEDTODISCUSS:
                newTaskData.NEEDTODISCUSS = item?.tasks;
                break;
              case STATUS.INPROGRESS:
                newTaskData.INPROGRESS = item?.tasks;
                break;
              case STATUS.UAT:
                newTaskData.UAT = item?.tasks;
                break;
              case STATUS.TESTING:
                newTaskData.TESTING = item?.tasks;
                break;
              case STATUS.DEVELOPED:
                newTaskData.DEVELOPED = item?.tasks;
                break;
              default:
                break;
            }
          });
          setTaskData(newTaskData);
          setSearchTaskData(newTaskData);
          // setlocalporject(response?.result);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchTasksByStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectid,
    defaultUser,
    project,
    fromdate,
    todate,
    priority,
    boardRefresh,
    selectedAssignee,
  ]);

  const onDragEnd = async (result: any) => {
    try {
      setIsLoading(true);
      const { source, destination } = result;
      if (!destination) return;
      const taskCategories = Object.keys(taskData);
      const sourceIndex = taskCategories.indexOf(source?.droppableId);
      const destinationIndex = taskCategories.indexOf(destination?.droppableId);
      result.task = { ...taskData[source?.droppableId][source?.index] };
      // if destination is BACKLOG, DONE, DUPLICATE
      if (destinationIndex === -1) {
        const columnData = [...taskData[source?.droppableId]];
        columnData?.splice(source?.index, 1);

        const tasks = {
          ...taskData,
          [source?.droppableId]: columnData,
        };
        setTaskData(tasks);
        destination?.droppableId !== "BACKLOG" &&
          updateStatus(
            result?.task?.id,
            STATUS[destination?.droppableId],
            result?.task
          );
      }
      if (
        sourceIndex < 0 ||
        sourceIndex >= taskCategories?.length ||
        destinationIndex < 0 ||
        destinationIndex >= taskCategories?.length
      ) {
        return;
      }
      const taskId = result?.draggableId;
      const taskDestinationStatus = +STATUS[destination?.droppableId];
      await HandleStatusDropdown(
        result,
        taskId,
        sourceIndex,
        destinationIndex,
        taskDestinationStatus
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result: any = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    if (endIndex > 0) {
      result[endIndex].orderno = String(
        Number(result[endIndex - 1].orderno) + 1
      );
    }
    return result;
  };
  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone: any = Array.from(source);
    const destClone: any = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource?.index, 1);
    removed.tStatus = STATUS[droppableDestination?.droppableId];
    destClone?.splice(droppableDestination.index, 0, removed);
    if (droppableDestination.index > 0) {
      destClone[droppableDestination.index].orderno = String(
        Number(destClone[droppableDestination?.index - 1]?.orderno) + 1
      );
    }
    const result: any = {};
    destClone?.map(
      (value: any) => (value.boardno = droppableDestination?.droppableId)
    );
    result[droppableSource?.droppableId] = sourceClone;
    result[droppableDestination?.droppableId] = destClone;
    return result;
  };
  const HandleStatusDropdown = async (
    result: any,
    taskId: string,
    sourceIndex: number,
    destinationIndex: number,
    taskDestinationStatus: number
  ) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const stateArrValues: any = Object.values(taskData);
    const stateArrKeys = Object.keys(taskData);
    if (sourceIndex === destinationIndex) {
      const items = reorder(
        stateArrValues[sourceIndex],
        source?.index,
        destination?.index
      );
      stateArrValues[sourceIndex] = items;
      setTaskData((p: any) => ({
        ...p,
        [stateArrKeys[sourceIndex]]: stateArrValues[sourceIndex],
      }));
    } else {
      const moveResult: any = move(
        stateArrValues[sourceIndex],
        stateArrValues[destinationIndex],
        source,
        destination
      );
      stateArrValues[sourceIndex] = moveResult[stateArrKeys[sourceIndex]];
      stateArrValues[destinationIndex] =
        moveResult[stateArrKeys[destinationIndex]];
      setTaskData((p: any) => ({
        ...p,
        [stateArrKeys[sourceIndex]]: stateArrValues[sourceIndex],
        [stateArrKeys[destinationIndex]]: stateArrValues[destinationIndex],
      }));
      updateStatus(taskId, taskDestinationStatus, result?.task);
    }
  };

  const onSelectDropDown = ({
    destinationDroppableId,
    sourceIndex,
    sourceDroppableId,
    task,
  }: {
    destinationDroppableId: string;
    sourceIndex: number;
    sourceDroppableId: string;
    task: any;
  }) => {
    const result = {
      destination: {
        index: 0,
        droppableId: destinationDroppableId, //STATUS_ARRAY[v],
      },
      source: {
        index: sourceIndex, //index,
        droppableId: sourceDroppableId, //STATUS_ARRAY[v],
        //  droppableId: STATUS_ARRAY[task?.TStatus],
      },
      draggableId: task?.id,
      task, //task?._id,
    };
    if (destinationDroppableId === "BACKLOG") {
      updateTaskSprint({ taskId: task?.id, sprintId: "", task });
    }
    onDragEnd(result);
  };
  const updateTaskSprint = async ({
    taskId,
    sprintId,
    task,
  }: {
    taskId: string;
    sprintId: any;
    task: any;
  }) => {
    try {
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.updateTaskSprint + "?",
        {
          id: taskId,
          sprintId: sprintId,
        }
      );
      addSprintChangeLog(task, "Backlog", task?.sprint[0]?.name);
      return res;
    } catch (e) {
      console.log("error", e);
    }
  };
  const updateStatus = async (
    taskId: string,
    status: number | string,
    task: any
  ) => {
    try {
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.updateStatus + "?",
        {},
        {
          tid: taskId,
          tStatus: status,
        }
      );
      if (status !== STATUS.DONE) {
        message.success({
          content: "Task Status updated successfully.",
          duration: 2, // Adjust the duration if needed
        });
      }

      // Notify only when the task is marked as "Done"
      if (status === STATUS.DONE) {
        notification.open({
          message: "🎉 Nice one!",
          placement: "bottomLeft",
          description: `Marked "${task?.uKey}" as Done`,
          icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
          btn: (
            <a
              href={`/task/${task?.uKey}`}
              style={{ color: "#1890ff", fontWeight: 600 }}
            >
              View task
            </a>
          ),
        });
      }

      addStatusChangeLog(task, status, task?.tStatus);
      return res;
    } catch (error) {
      console.log("error", error);
      message.error({
        content: "Failed to update task Status.",
        duration: 2, // You can adjust the duration if needed
      });
    } finally {
      dispatch(refreshTaskDrawer());
      dispatch(refreshBoard());
    }
  };

  const breadcrumbItems = [
    {
      title: (
        <Link to={"/dashboard"} className="BreadcrumbLink">
          Home
        </Link>
      ),
    },
    {
      title: "Board",
      // href: "/board",
    },
  ];
  // Handle avatar click function
  const handleAvatarClick = (assignee: any) => {
    setSelectedAssignee(assignee);
    fetchTasksByStatus(null, assignee);
    // setSelectedAvatarId(assignee);
  };
  const handleViewModeChange = (value: string) => {
    if (value === "List") {
      dispatch(setBoardViewMode("list"));
    } else if (value === "Kanban") {
      dispatch(setBoardViewMode("grid"));
    }
  };
  return (
    <>
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined spin />}
        size="default"
      >
        <Row justify={"end"} className="mb-2">
          <Col style={{ fontSize: "12px", textAlign: "center" }}>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>

        <Layout style={{ backgroundColor: "white" }}>
          <Row style={{ backgroundColor: "white" }}>
            <Col
              md={24}
              lg={16}
              xl={18}
              xxl={19}
              style={{ marginTop: "8px", height: "86.5vh" }}
            >
              <div>
                {taskData && (
                  <>
                    <div
                      className="ToothierHide"
                      style={{ paddingLeft: "26px" }}
                    >
                      <Toothbar
                        page="board"
                        setstatus={setstatus}
                        setPriority={setPriority}
                        setFromdate={setFromdate}
                        setTodate={setTodate}
                        setStatusFilter={setStatusFilter}
                        setDefaultUser={setDefaultUser}
                        setSelectedAssignee={setSelectedAssignee}
                        defaultUser={defaultUser}
                        setIsLoading={setIsLoading}
                        projecturlname={projecturlname}
                        setTitleImg={setTitleImg}
                        userfilterid={userfilterid}
                        setUserFilterId={setUserFilterId}
                        // setUserFilterpId={setUserFilterpId}
                        randomcolor={randomcolor}
                        // setAssignedby={setAssignedby}
                        setRandomColor={setRandomColor}
                        titleimg={titleimg}
                        setProjectImage={setProjectImage}
                        setProjectName={setProjectName}
                        // setProjectId={setProjectId}
                        projects={projects}
                        isLoading={isLoading}
                        projectid={projectid}
                        projectname={projectname}
                        setProjects={setProjects}
                        handleAvatarClick={handleAvatarClick}
                        // selectedAvatarId={selectedAvatarId}
                        // setSelectedAvatarId={setSelectedAvatarId}
                        taskData={taskData}
                        setTaskData={setTaskData}
                        // localproject={localproject}
                        setLoadData={setLoadData}
                        // todo={toDo}
                        boardViewMode={boardViewMode}
                        handleViewModeChange={handleViewModeChange}
                    
                        // setTaskSearchFilter={setTaskSearchFilter}
                        // setSearchTaskData={setSearchTaskData}
                        searchTaskData={searchTaskData}
                      />
                      {boardViewMode === GRID_VIEW ? (
                        <div></div>
                      ) : (
                        <Row
                          className="mt-1 mb-1 Grid-view"
                          justify={"space-between"}
                        >
                          <Col
                            span={2}
                            offset={1}
                            style={{
                              color: "#5e6c84",
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Ukey
                          </Col>
                          <Col
                            span={4}
                            style={{
                              color: "#5e6c84",
                              fontWeight: 600,
                            }}
                          >
                        Title
                          </Col>
                          <Col
                            span={4}
                            style={{
                              textAlign: "end",
                              color: "#5e6c84",
                              fontWeight: 600,
                            }}
                          >
                            Status
                          </Col>
                          <Col
                            style={{
                              color: "#5e6c84",
                              fontWeight: 600,
                            }}
                            span={8}
                          >
                            <span className="mr-8">Assignee</span>
                            <span className="mr-3">Assignee By</span> Due Date
                          </Col>
                        </Row>
                      )}
                    </div>
                    <DragableBoard
                      page="board"
                      tasks={taskData}
                      viewMode={boardViewMode}
                      onDragEnd={onDragEnd}
                      showLoading={showLoading}
                      onSelectDropDown={onSelectDropDown}
                      onClick={(record: any) => {
                        setSelectedRecord(record);
                      }}
                    />
                  </>
                )}
              </div>
            </Col>

            <Col
              // xs={24}
              // sm={24}
              md={24}
              lg={8}
              xl={6}
              xxl={5}
              // span={5}
              style={{ marginTop: "8px", height: "80vh" }}
            >
              <ActiviyLogs y={"75vh"} params={{ projectid: project?.id }} />
            </Col>
          </Row>
          {open && (
            <AddEditTask
              id={selectedRecord?.id}
              onDismiss={(rec: boolean) => {
                // setSelectedId("")
                setOpen(!open);
              }}
            />
          )}
        </Layout>
      </Spin>
    </>
  );
};

export default Board;
