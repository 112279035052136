import { DragDropContext } from "@hello-pangea/dnd";
import DroppableColumn from "./DroppableColumn";
import { GRID_VIEW } from "../../constants/commonConstants";

const DragableBoard = (props: any) => {
  const tasks = props?.tasks;

  const getSprintData = (status: any, sprintData: any) => {
    if (status && sprintData) {
      return sprintData?.find((t: any) => t?.id === status);
    }
  };
  return (
    <DragDropContext onDragEnd={props?.onDragEnd}>
      <div
        style={{
          display: props?.viewMode === GRID_VIEW ? "flex" : "block",
          marginLeft: "19px",
          marginRight: "10px",
          overflowX: "auto",
          height: "74vh",
          paddingRight: "16px",
          cursor: "n-resize",
        }}
      >
        {tasks &&
          Object.keys(tasks)?.map((status, i) => (
            <DroppableColumn
              i={i}
              key={status}
              status={status}
              page={props?.page}
              tasks={[...tasks?.[status]]}
              viewMode={props?.viewMode}
              onDragEnd={props?.onDragEnd}
              showLoading={props?.showLoading}
              isLoading={props?.isLoading}
              sprintData={getSprintData(status, props?.sprintData)}
              onSelectDropDown={props?.onSelectDropDown}
              onClick={props?.onClick}
            />
          ))}
      </div>
    </DragDropContext>
  );
};

export default DragableBoard;
