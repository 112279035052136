import React, { useEffect, useState } from "react";
import { Button, Drawer, Flex, Form, Input, Modal, Row, message } from "antd";
import { useAddBranch } from "../../Services/RQMastersService";
import { CloseOutlined } from "@ant-design/icons";
import { queryClient } from "../..";
const AddEditBranch = (props: any) => {
  const { mutateAsync, isPending } = useAddBranch();
  const drawerBranch = props?.data?.id ? "Edit Branch" : "Add Branch";
  const [open, setOpen] = useState(true);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(props?.data);
  }, []);

  const handleSubmit = async () => {
    try {
      const values = await form?.validateFields();
      const val: any = {
        name: values?.name?.trim(),
        id: props?.data?.id,
      };
      const res = await mutateAsync(val);
      if (res?.status) {
        await queryClient.invalidateQueries({
          queryKey: ["taskBranchListOptions"],
        });
        if (props?.data?.id) {
          message.success("Branch updated successfully!");
        } else {
          message.success("Branch submitted successfully!");
        }
        onCancel();
        props?.setSelectedBranch([
          {
            value: res?.result?.id,
            label: res?.result?.name,
            key: res?.result?.id,
          },
        ]);
      } else {
        message.error(res?.message || "Something went wrong.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setSubmittable(false);
    }
  };
  const onCancel = () => {
    form.resetFields();
    setOpen(() => !open);
    setTimeout(() => {
      props?.onDismiss();
    }, 300);
  };
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <>
      <Drawer
        title={drawerBranch}
        extra={
          <>
            <CloseOutlined className="cursor" onClick={onCancel} />
          </>
        }
        closeIcon={false}
        open={open}
        footer={
          <>
            <Row justify={"end"}>
              <Button className="capTask-btn-light btn-m-sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                loading={isPending}
                className="btn-m-sm"
                type="primary"
                onClick={handleSubmit}
                disabled={!submittable}
              >
                Save
              </Button>
            </Row>
          </>
        }
        width={500}
      >
        <Form
          form={form}
          labelAlign="left"
          layout="horizontal"
          labelCol={{ span: 5 }}
          requiredMark={false}
          initialValues={{ orderNo: 0 }}
          onFinish={() => message.success("Submit success!")}
          onFinishFailed={() => message.error("Submit failed!")}
          className="DrawerPadding"
        >
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[{ required: true, message: "Please select Branch Name!" }]}
          >
            <Input
              variant="borderless"
              placeholder="Branch name"
              className="borderLessInput"
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddEditBranch;
