import { Button, Skeleton, Space, Table, Tag, Tooltip, Typography } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { useState } from "react";
import "./Reports.css";
import NoData from "../../pages/NoData";
import { Link, useNavigate } from "react-router-dom";
import TableSkeleton from "../../pages/dashboard/Common/TableSkeleton";
import "../../common/common.css";
interface Assignee {
  name: string;
}

interface Ukey {
  title: any;
  uKeys: string;
}

interface UserByProject {
  _id: Assignee;

  uKeys: Ukey[];
}

interface InHandProps {
  projectid1: string;
  userbyproject: UserByProject[];
  IsLoading: boolean;
}
const InHand: React.FC<InHandProps> = ({ userbyproject, IsLoading }) => {
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: 80,
      fixed: 'left',
      render: (text: string, record: UserByProject, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: "Assignee",
      dataIndex: "_id",
      key: "name",
       width: 150,
      fixed: 'left',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Assignee - b.Assignee,
      render: (item: Assignee) => (
        <Typography.Text ellipsis >
          {item?.name}
        </Typography.Text>
      ),
    },
    {
      title: "Ukey",
      dataIndex: "uKeys",
      key: "ukey",
       width:650,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Ukey - b.Ukey,
      render: (uKeys: Ukey[]) => (
        <>
          {uKeys?.map((item: any, index) => (
            <Tooltip title={item?.title} key={index}>
              <Button

                type="link"
                className="inHandHover"
                // onClick={() => navigate("/task/" + item?.taskId)}
              >
                <Link to={`/task/${item?.taskId}`}>
                  {item?.uKeys?.toUpperCase()}
                </Link>
              </Button>
            </Tooltip>
          ))}
        </>
      ),
    },

  ];
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 10,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });

    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <>
      {IsLoading ? (
        <TableSkeleton
          columns={columns}
          scroll={{ y: "75vh" }}
          style={{
            scrollbarWidth: "none",
            borderTop: "solid 1px #b2bec3",
          }}
        />
      ) : (
        <div className="pr-6 pl-6 pt-2">
          <Table
            // bordered
            className="issuestabel capTask-custom-table"
            // scroll={{ y: "71vh" }}
            scroll={{ x: 'max-content', y: '71vh'  }}
            dataSource={userbyproject?.map((data: any, index: number) => ({
              serial: index + 1,
              key: index,
              ...data,
            }))}
            onChange={onChangeTable}
            pagination={{
              showTotal: (total: any, range: any) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: listParams.start / listParams.length + 1,
              pageSize: listParams.length,
              showSizeChanger: true,
              pageSizeOptions: pageSizeOption,
              position: ["bottomRight"],
            }}
            style={{ borderTop: "solid 1px #b2bec3" }}
            columns={columns}
            locale={{
              emptyText: (
                <Typography.Title
                  level={4}
                  style={{ textAlign: "center", paddingTop: "47px" }}
                >
                  {/* <NoData message={undefined} /> */}
                  <img
                    style={{ width: 140, paddingBottom: 15 }}
                    // src={process.env.PUBLIC_URL + "/noTask.svg"}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                  <div style={{ textAlign: "center" }}>
                    <h3
                      style={{
                        margin: 0,
                        fontFamily: "Open Sans",
                        fontSize: 16,
                        color: "rgb(72 81 110)",
                      }}
                    >
                      Please select project
                    </h3>
                    <h3
                      style={{
                        margin: 0,
                        fontFamily: "Open Sans",
                        fontSize: 16,
                        color: "rgb(72 81 110)",
                      }}
                    >
                      And Click the search button to get Task
                    </h3>
                  </div>
                </Typography.Title>
              ),
            }}
          />
        </div>
      )}
    </>
  );
};

export default InHand;
