import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { memo, useEffect, useMemo, useState } from "react";
import { AddEditApis } from "../../Services/RQTaskService";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import {
  useProjectGroup,
  useProjectGroupById,
} from "../../Services/RQMastersService";

export interface IAddEditProjectGroup {
  groupId?: string | null | undefined;
  onDismiss?: () => void;
}

const AddEditProjectGroup = ({ groupId, onDismiss }: IAddEditProjectGroup) => {
  const [searchFilter, setSearchFilter] = useState<{
    project: {
      search: undefined;
    };
  }>({
    project: {
      search: undefined,
    },
  });
  const user = useSelector(userData);
  const { useProject } = AddEditApis;
  const { mutateAsync, isPending } = useProjectGroup();
  const { data: getByIdApiData, isPending: isGetByIdPending } =
    useProjectGroupById(groupId);
  const { data: projectDataList, isPending: isProjectPending } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });
  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);

  const getByIdData = useMemo(() => {
    if (getByIdApiData?.message) {
      message.error(`Error => ${getByIdApiData?.message}`);
      return undefined;
    } else return getByIdApiData?.result;
  }, [getByIdApiData]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState(true);
  const [form] = Form.useForm();
  useEffect(() => {
    // form.setFieldsValue(props?.data);
  }, []);
  const onCancel = () => {
    form.resetFields();
    setOpen(() => !open);
    setTimeout(() => {
      onDismiss && onDismiss();
    }, 300);
  };

  const handleSubmit = () => {
    form.validateFields().then(async (values: any) => {
      let val = {
        ...values,
        id:!!groupId?groupId:undefined,
        projects: values?.projects?.map((itm: any) => ({
          id: itm?.value,
          name: itm?.label,
        })),
      };
      const res = await mutateAsync(val);
      if (res?.message) message.error(`Error => ${res?.message}`);
      else {
        message.success("Group added successfully");
        onCancel();
      }
    });
  };

  useEffect(() => {
    if (!!getByIdData && !!groupId) {
      let val = {
        ...getByIdData,
        projects: getByIdData?.projects?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        })),
      };
      console.log("val l34r324 = ", val);

      form.setFieldsValue({ ...val });
      // setApiData({...val})
    }
  }, [getByIdData]);
  return (
    <>
      <Drawer
        title={`${!!!groupId ? "Add" : "Edit"} Group`}
        extra={
          <>
            <CloseOutlined className="cursor" onClick={onCancel} />
          </>
        }
        closeIcon={false}
        open={open}
        footer={
          <>
            <Row justify={"end"}>
              <Button className="capTask-btn-light btn-m-sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                loading={isPending}
                className="btn-m-sm"
                type="primary"
                onClick={handleSubmit}
                disabled={isPending}
              >
                Save
              </Button>
            </Row>
          </>
        }
        width={500}
      >
        <Spin
          spinning={isProjectPending || (!!groupId ? isGetByIdPending : false)}
          indicator={<LoadingOutlined />}
        >
          <Form
            form={form}
            labelAlign="left"
            layout="horizontal"
            labelCol={{ span: 5 }}
            requiredMark={false}
            className="DrawerPadding"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Input
                variant="borderless"
                placeholder="Name"
                className="borderLessInput"
              />
            </Form.Item>
            <Form.Item
              label="Projects"
              name="projects"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select
                mode="multiple"
                labelInValue
                searchValue={searchFilter?.project?.search}
                variant="borderless"
                className="borderLessInput w100"
                options={projectOptions}
                placeholder="Select Project(s)"
                showSearch
                onSearch={(value) => {
                  setSearchFilter((pre: any) => ({
                    ...pre,
                    project: {
                      search: value,
                    },
                  }));
                }}
                onBlur={() => {
                  setSearchFilter((prev: any) => ({
                    ...prev,
                    project: {
                      search: null, // Clear the search value on focus
                    },
                  }));
                }}
                maxTagCount={"responsive"}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    overlayStyle={{ pointerEvents: "none" }}
                    title={omittedValues.map(({ label }) => label).join(", ")}
                  >
                    <span>Hover Me</span>
                  </Tooltip>
                )}
                optionFilterProp="label"
              />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default memo(AddEditProjectGroup);
