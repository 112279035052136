import React, { useEffect, useState } from "react";
import {
  getAvatarUrl,
  getEllipsisText,
  randomhax,
  statusbackgroundgenerator,
  statusconvertor,
} from "../utils/Util";
import { format } from "date-fns";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HTTPSCalls from "../Services/HTTPCalls";
import {
  Avatar,
  Card,
  Col,
  Row,
  Typography,
  Table,
  Tooltip,
  Modal,
  Button,
  Breadcrumb,
  TableProps,
  Divider,
  Input,
} from "antd";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Filter from "./PeoplesReportToothbar/PeoplesReportToothbar";
import TableSkeleton from "./dashboard/Common/TableSkeleton";

const CardItems = {
  fontSize: "13px",
  fontWeight: 600,
};
const CardPeoplestyld = {
  margin: "0 7px",
  border: "1px solid rgb(243, 245, 249)",
  borderRadius: 10,
  // width: "4vw",
  padding: 7,
  backgroundColor: "rgb(245, 248, 255)",
};
// UserRelatedTasks Css //
const reUserrelatedtaskspotCard = {
  height: "40px",
  width: "40px",
  borderRadius: 5,
  fontSize: 14,
  backgroundColor: "#eef2f9",
  fontWeight: 600,
  marginLeft: 10,
  marginRight: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
// const Tree = {
//   borderRadius: "3px",
// };
function Report() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userid");
  const state = location.state as {
    userid: string;
  };
  const [statusFilter, setStatusFilter] = useState<Array<number>>([
    1, 2, 3, 4, 5,
  ]);
  const [userRelatedTasksFilter, setUserRelatedTasksFilter] = useState<
    Record<string, any>
  >({ projectid: "", userid: userId });
  const [userRelatedTasks, setUserRelatedTasks] = useState<any>([]);
  const [userRelatedProject, setUserRelatedProject] = useState<any>([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [userData, setUserData] = useState<any>({});
  const [selectedUser, setSelectedUser] = useState<string>("");

  const GetUserRelatedTasks = async () => {
    setIsLoading(true);
    try {
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.userReportData + "?",
        { statusFilter },
        userRelatedTasksFilter
      );
      if (response?.result) {
        setUserRelatedTasks(response?.result);
        // console.log("ash", response.result);
        const tasks = response?.result?.map(
          (task: any, i: any, record: any) => {
            return {
              sno: i + 1,
              key: i,
              ukey: (
                <Row className="table-Title">
                  <Col>
                    <Link to={`/task/${task?.id}`}>
                      <Button
                        size="small"
                        className="resume-btn-sm mt-1"
                        style={{
                          backgroundColor: "rgb(223, 246, 221)",
                          width: "100px",
                          border: "none",
                        }}
                        // onClick={() => {
                        //   navigate("/task/" + task?.id);
                        // }}
                      >
                        <Tooltip title={task?.uKey}>
                          <Typography.Paragraph
                            style={{
                              color: "rgb(16, 124, 16)",
                              fontSize: "12px",
                            }}
                            ellipsis
                          >
                            {task?.uKey}
                          </Typography.Paragraph>
                        </Tooltip>
                      </Button>
                    </Link>
                    {/* <Button
                    size="small"
                    className="resume-btn-sm mt-1"
                    style={{
                      color: "rgb(16, 124, 16)",
                      backgroundColor: "rgb(223, 246, 221)",
                      width: "6vw",
                      border: "none",
                    }}
                    onClick={() => {
                      if (isUserAdmin()) {
                        navigate("/task/" + task?.id);
                      } else {
                        message.warning("You do not have admin access.");
                      }
                    }}
                  >
                    {task?.uKey}
                  </Button> */}
                  </Col>
                </Row>
              ),
              name: (
                <Row className="table-Title">
                  <Col>
                    <Tooltip title={task?.name}>
                      <Typography.Paragraph
                        ellipsis
                        style={{
                          marginTop: 0,
                          fontSize: 14,
                          color: "#636e72",
                          cursor: "pointer",
                        }}
                      >
                        {/* {task?.name} */}
                        {getEllipsisText(task?.name, 20)}
                      </Typography.Paragraph>
                    </Tooltip>
                  </Col>
                </Row>
              ),
              //title
              title: (
                <Row>
                  <Col lg={24}>
                    <Tooltip title={getEllipsisText(task?.title, 150)}>
                      <Typography.Paragraph style={{ cursor: "pointer" }}>
                        {getEllipsisText(task?.title, 22)}
                      </Typography.Paragraph>
                    </Tooltip>
                  </Col>
                </Row>
              ),
              //status
              status: (
                <Button
                  size="small"
                  className="resume-btn-sm"
                  style={{
                    backgroundColor: statusbackgroundgenerator(
                      statusconvertor(task?.status)
                    ),
                    color: "rgb(12, 84, 96)",

                    width: "6vw",
                    border: "none",
                  }}
                >
                  <Typography.Text ellipsis style={{ fontSize: "12px" }}>
                    {statusconvertor(task?.status)}
                  </Typography.Text>
                </Button>
              ),
              //priority
              priority: (
                <>
                  <Typography.Text ellipsis className="pr-2">
                    <span className="PriorityIconRemove">
                      {priorityIcon(task?.priority)}
                    </span>
                  </Typography.Text>

                  <Typography.Text ellipsis style={{ cursor: "pointer" }}>
                    {/* <span className="PriorityIconRemove"> */}
                    {prioritycheck(task?.priority)}
                    {/* </span> */}
                  </Typography.Text>
                </>
              ),
              // sprintName
              sprintName: (
                <div style={{ cursor: "pointer", width: "150px" }}>
                  <Tooltip title={task?.name}>
                    <Typography.Paragraph ellipsis>
                      {getEllipsisText(
                        task?.sprint[0]?.name
                          ? task?.sprint[0]?.name
                          : "Backlog",
                        20
                      )}
                    </Typography.Paragraph>
                  </Tooltip>
                </div>
              ),
              //startDate
              startDate: (
                <Row style={{ cursor: "pointer", width: "100px" }}>
                  <Tooltip
                    title={
                      task?.sprint?.length > 0
                        ? `Sprint Date: ${format(
                            new Date(task?.sprint[0]?.startDate),
                            "dd MMM yyyy"
                          )}`
                        : "No sprint start date available"
                    }
                  >
                    <Typography.Text>
                      {task?.sprint?.length > 0
                        ? format(
                            new Date(task?.sprint[0]?.startDate),
                            "dd MMM yyyy"
                          )
                        : "-"}
                    </Typography.Text>
                  </Tooltip>
                </Row>
              ),
              // endDate
              endDate: (
                <Row style={{ cursor: "pointer", width: "100px" }}>
                  <Tooltip
                    title={
                      task?.sprint?.length > 0
                        ? `Sprint Date: ${format(
                            new Date(task?.sprint[0]?.endDate),
                            "dd MMM yyyy"
                          )}`
                        : "No sprint End date available"
                    }
                  >
                    <Typography.Text>
                      {task?.sprint?.length > 0
                        ? format(
                            new Date(task?.sprint[0]?.endDate),
                            "dd MMM yyyy"
                          )
                        : "-"}
                    </Typography.Text>
                  </Tooltip>
                </Row>
                // <Row style={{ cursor: "pointer", width: "100px" }}>
                //   <Typography.Text
                //     style={{ cursor: "pointer", width: "100px" }}
                //   >
                //     {task?.sprint?.length > 0
                //       ? format(task?.sprint[0]?.endDate, "dd MMM yyyy")
                //       : "-"}
                //   </Typography.Text>
                // </Row>
              ),
            };
          }
        );

        setUserRelatedTasks(tasks);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  const GetUserRelatedProject = async () => {
    try {
      setIsLoading(true);
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.project.getAll + "?",
        {
          start: 0,
          length: 3000,
          uid: selectedUser ? selectedUser : userId,
          isAllRecord: true,
        }
      );
      if (response?.result) {
        setUserRelatedProject(response?.result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const prioritycheck = (priority: any) => {
    switch (priority) {
      case 2:
        return "High";
      case 1:
        return "Medium";
      case 0:
        return "Low";
      default:
        return "Unknown";
    }
  };

  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };

  const columns = [
    {
      title: <Typography.Text ellipsis>S.No</Typography.Text>,
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (text: string, record: any, index: number) => text,
    },
    {
      title: "Ukey",
      dataIndex: "ukey",
      isSortable: false,
      // width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: false,
      width: 100,

      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Name - b.Name,
    },
    {
      title: "Title",
      dataIndex: "title",
      isSortable: false,
      width: 210,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.title - b.title,
    },
    {
      title: "Status",
      dataIndex: "status",
      showSorterTooltip: false,
      // width: "16%",
      sorter: (a: any, b: any) => a.status - b.status,
    },
    {
      title: <Typography.Text ellipsis>Priority</Typography.Text>,
      dataIndex: "priority",
      width: 120,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.priority - b.priority,
    },
    {
      title: "Sprint name",
      dataIndex: "sprintName",
      //  width: 50,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.sprintName - b.sprintName,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      //  width: 40,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.startDate - b.startDate,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      //  width: 20,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.endDate - b.endDate,
    },
  ];

  // function StatusSelectHandler(selectedValue: any) {
  //   setStatusFilter(selectedValue.map((item: any) => item.value));
  // }

  useEffect(() => {
    GetUserRelatedTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRelatedTasksFilter]);

  const getUserData = async () => {
    try {
      setIsLoading(true);
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.user.getAssigneeByTaskCount + "?",
        {
          assigneeid: selectedUser ? selectedUser : userId,
        }
      );
      if (response?.result) {
        // console.log("response.result", response.result);
        setUserData(response?.result[0]);

        // setUserRelatedProject(response.result);
        GetUserRelatedProject();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);
  const breadcrumbItems = [
    {
      title: (
        <Link to={"/peoples"} className="BreadcrumbLink">
          Peoples
        </Link>
      ),
    },
    {
      title: "reporting",
    },
  ];
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });

    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging?.current - 1) * paging.pageSize);
      changeListParams("length", paging?.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  // Input search
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // Filter projects based on search query
  const filteredProjects = userRelatedProject?.filter((project: any) =>
    project?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );
  const [avatarSize, setAvatarSize] = useState(100);

  // Function to update avatar size based on screen width
  const updateAvatarSize = () => {
    if (window.innerWidth < 600) {
      setAvatarSize(50);
    } else if (window.innerWidth < 1200) {
      setAvatarSize(75);
    } else {
      setAvatarSize(100);
    }
  };
  useEffect(() => {
    // Set the initial avatar size
    updateAvatarSize();

    // Listen for window resize events
    window.addEventListener("resize", updateAvatarSize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateAvatarSize);
  }, []);
  return (
    <>
      <>
        <Row justify={"space-between"} className="mb-2">
          <Col span={1}>
            <Button type="link" onClick={() => navigate("/peoples")}>
              <ArrowLeftOutlined />
            </Button>
          </Col>
          <Col style={{ fontSize: "12px", textAlign: "center" }}>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>
        <Row
          className="roundedCornerSmall bg-white"
          style={{ padding: 14 }}
          justify={"space-between"}
        >
          <Col span={24} className="mb-3">
            <Filter
              onSearchbar={GetUserRelatedTasks}
              page={undefined}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              setUserRelatedTasksFilter={setUserRelatedTasksFilter}
              setSelectedUser={setSelectedUser}
              userId={userId}
              // filteredProjects={filteredProjects}
            />
          </Col>

          <Col lg={6} xl={7} xxl={5} md={15} className="Item1">
            {/* className="peoples-Card" */}
            <Card
              className="CardWidthSet"
              style={{
                //  width:'22vw',
                margin: "0px 18px",
                //  width:'20vw',
                // overflowX:'scroll',
                zIndex: 1,
              }}
              actions={[
                <div style={CardPeoplestyld}>
                  <div style={CardItems}>{userData?.todo}</div>
                  <div
                    className="peoples-font-size peoples-font"
                    style={{ color: "#a986d4" }}
                  >
                    To Do
                  </div>
                </div>,
                <div className="custom-font-size" style={CardPeoplestyld}>
                  <div style={CardItems}> {userData?.inprogress}</div>
                  <div
                    className="peoples-font-size peoples-font"
                    style={{ color: "#64b4fe" }}
                  >
                    In Progress
                  </div>
                </div>,
                <div className="custom-font-size" style={CardPeoplestyld}>
                  <div style={CardItems}> {userData?.done}</div>
                  <div
                    className="peoples-font-size peoples-font"
                    style={{ color: "#a986d4" }}
                  >
                    Done
                  </div>
                </div>,
              ]}
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                    paddingTop: 31,
                    backgroundColor: "rgb(237, 240, 245)",
                    height: 100,
                    borderRadius: 5,
                  }}
                >
                  <Col
                    span={24}
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => setModalVisible(true)}
                  >
                    <Avatar size={avatarSize} src={getAvatarUrl(userData)} />
                  </Col>

                  {/* Modal to show larger avatar */}
                  <Modal
                    title={userData?.name}
                    open={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                  >
                    <img
                      src={getAvatarUrl(userData)}
                      alt="Avatar"
                      style={{ width: "100%" }}
                    />
                  </Modal>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Col span={24} className="mt-8">
                    <Typography.Text
                      ellipsis
                      style={{
                        color: "rgb(79, 93, 118)",
                        fontWeight: "bold",
                      }}
                    >
                      {userData?.name}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Link ellipsis type="secondary">
                      {userData?.email}
                    </Typography.Link>
                  </Col>
                </Col>
              </Row>
            </Card>

            <Card
              style={{ height: "45vh", margin: "9px 18px" }}
              className="Card-refresh CardWidthSet"
            >
              <Row justify={"space-between"} align={"middle"}>
                <Col span={13}>
                  <Typography.Link
                    ellipsis
                    style={{
                      fontSize: 14.5,
                      fontWeight: 600,
                      margin: 9,
                    }}
                  >
                    Projects Worked on
                  </Typography.Link>
                </Col>
                <Col className="ml-2 mt-1">
                  <SearchOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: 17,
                      color: searchVisible ? "#1677ff" : undefined,
                    }}
                    onClick={() => setSearchVisible(!searchVisible)}
                  />
                </Col>
                <Col span={23} className="ml-2 mt-2">
                  {searchVisible && (
                    <Input
                      className="borderLessInput w100"
                      placeholder="Search Projects"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e?.target?.value)}
                      allowClear
                    />
                  )}
                </Col>
              </Row>

              <Row
                className="mt-2"
                style={{
                  maxHeight: "34vh",
                  overflowY: "scroll",
                  overflowX: "clip",
                  scrollbarWidth: "auto",
                }}
              >
                {filteredProjects?.length > 0 ? (
                  filteredProjects?.map((project: any, index: number) => (
                    <React.Fragment key={index}>
                      <Row
                        justify={"space-between"}
                        onClick={() => {
                          setUserRelatedTasksFilter((prevState) => ({
                            ...prevState,
                            projectid: project?.id,
                          }));
                        }}
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          marginBottom: 9,
                        }}
                      >
                        <Col span={2}>
                          <Card
                            style={{
                              ...reUserrelatedtaskspotCard,
                              color: randomhax(index),
                            }}
                          >
                            {getEllipsisText(project?.alias, 3)}
                          </Card>
                        </Col>
                        <Col>
                          <Tooltip title={project?.name}>
                            <Typography.Text
                              ellipsis
                              style={{ fontWeight: 400 }}
                            >
                              {getEllipsisText(project?.name, 32)}
                            </Typography.Text>
                          </Tooltip>
                        </Col>
                      </Row>
                      <Divider style={{ margin: 11 }} />
                    </React.Fragment>
                  ))
                ) : (
                  <Row style={{ textAlign: "center", width: "100%" }}>
                    <img
                      style={{
                        paddingTop: "40px",
                        width: 140,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      src={process.env.PUBLIC_URL + "/img-antd.svg"}
                      alt="No Task"
                    />
                  </Row>
                )}
              </Row>
            </Card>
          </Col>
          <Col lg={18} xl={17} xxl={19} md={24} className="peoples-Tabel">
            {IsLoading ? (
              <TableSkeleton
                columns={columns}
                scroll={{ y: "75vh" }}
                // style={{
                //   scrollbarWidth: "none",
                //   borderTop: "solid 1px #b2bec3",
                // }}
              />
            ) : (
              <Table
                className="capTask-custom-table"
                style={{
                  paddingTop: 5,
                  marginRight: -2,
                  height: "auto",
                  // borderTop: "solid 1px #b2bec3",
                }}
                scroll={{ x: "max-content" }}
                onChange={onChangeTable}
                pagination={{
                  showTotal: (total: any, range: any) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOption,
                  position: ["bottomRight"],
                }}
                columns={columns}
                dataSource={userRelatedTasks}
                loading={IsLoading}
                // scroll={{ y: "78vh", x: "max-content" }}
                locale={{
                  emptyText: (
                    <img
                      style={{ paddingTop: "40px", width: 140 }}
                      // src={process.env.PUBLIC_URL + "/noTask.svg"}
                      src={process.env.PUBLIC_URL + "/img-antd.svg"}
                      alt="No Task"
                    />
                  ),
                }}
              />
            )}
          </Col>
        </Row>
      </>
    </>
  );
}
export default Report;
