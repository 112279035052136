// src/context/BranchesContext.tsx

import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface Branch {
  name: string;
  html_url: string;
}

interface BranchesContextType {
  branches: Branch[];
  loading: boolean;
  error: string | null;
  fetchBranches: () => void;
}

const BranchesContext = createContext<BranchesContextType | undefined>(undefined);

interface BranchesProviderProps {
  children: ReactNode;
}

const BranchesProvider: React.FC<BranchesProviderProps> = ({ children }) => {
  const [branches, setBranches] = useState<Branch[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchBranches = () => {
    setLoading(true);
    setError(null)
    const token = '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'; // You should pass this token securely
    fetch(`https://api.github.com/repos/prashantsoni15/BookHotel/branches`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        setLoading(false);
      })
      .catch((err) => {
        setError('Error fetching repositories');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBranches(); // Fetch branches on component mount
  }, []);

  return (
    <BranchesContext.Provider value={{ branches, loading, error, fetchBranches }}>
      {children}
    </BranchesContext.Provider>
  );
};

const useBranches = (): BranchesContextType => {
  const context = React.useContext(BranchesContext);
  if (!context) {
    throw new Error('useBranches must be used within a BranchesProvider');
  }
  return context;
};

export { BranchesProvider, useBranches };
