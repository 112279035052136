import axios from "axios";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import HTTPSCalls from "../../Services/HTTPCalls";

const GithubAuthIntigrate = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const code = searchParams.get("code");
  console.log("code13212 = ",code);
  
  console.log("searchParams", searchParams.get("code"));

  const clientId = "Ov23li4662hVvtPtM738"; // Store in environment variables for security
  const client_secret = "28df841871cee3a311ab02e60d24e0b1a45425a1";
  const redirectUri = "http://localhost:3000/integrate"; // This is the URL that GitHub will redirect to after successful authentication
  const token = "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";

  //for creating code
  const handleClickForCode = () => {
    // GitHub OAuth URL for authorization
    const githubAuthUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user,repo`;
    debugger
    // Redirect user to GitHub for authentication
    window.location.href = githubAuthUrl;
  };

  //for creating tokes
  const handleLoginForToken = () => {
    // GitHub OAuth URL for authorization
    // const githubAuthUrl = `https://github.com/login/oauth/access_token?client_id=${clientId}&client_secret=${client_secret}&redirect_uri=${redirectUri}&scope=user,repo&code=${code}`;

    const params: any = new URLSearchParams({
      client_id: clientId ?? "",
      client_secret: client_secret ?? "",
      redirect_uri: redirectUri ?? "",
      code: code ?? "",
    });

    fetch("https://github.com/login/oauth/access_token", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: params,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Access Token:", data.access_token); // Use the access token
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // Redirect user to GitHub for authentication
    // window.location.href = githubAuthUrl;
  };

  console.log(process.env.REACT_APP_API_URL, "fsgdfss");

  const fun = async()=>{
    console.log("await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.github.authorize,{} = ",await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.github.authorize,{}))
  }
  
  useEffect(()=>{
    // fun()
  },[])

  return (
    <div>
      <div>
        <button
          onClick={() => {
            if (code) {
              handleLoginForToken();
            } else {
              handleClickForCode();
            }
          }}
        >
          {code ? "get token" : "get code"}
        </button>
      </div>
      <button
        onClick={() => {
          fetch(`https://api.github.com/users/KishanVaishnav-Capsitech/orgs`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`, // Authentication with personal access token
              Accept: "application/json", // Specify the GitHub API version
            },
          })
            .then((response) => response.json()) // Parse the response as JSON
            .then((repos) => {
              debugger;
              console.log("Repositories:", repos);
              repos.forEach((repo: any) => {
                console.log(
                  `Repository Name: ${repo.name}, URL: ${repo.html_url}`
                );
              });
            })
            .catch((error) => {
              console.error("Error fetching repositories:", error);
            });
        }}
      >
        get branches
      </button>
    </div>
  );
};

export default GithubAuthIntigrate;
