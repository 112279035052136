import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  ConfigProvider,
  Button,
  Avatar,
  Row,
  Tooltip,
  Col,
  Popover,
  Badge,
  Typography,
} from "antd";
import Meta from "antd/es/card/Meta";
import { useGetProjects } from "../../../Services/RQDashboardService";
import "../../../common/common.css";
import { getEllipsisText } from "../../../utils/Util";
import {
  projectData as PD,
  setProjectData,
} from "../../../store/slices/project-slice";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../../../store/slices/user-slice";
import { Link, useNavigate } from "react-router-dom";
import { DownOutlined, ReloadOutlined } from "@ant-design/icons";
import ProjectCradSkeleton from "../Common/ProjectCradSkeleton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { dayjs } from "../../../utils/dayjs";

const ProjectDetail = ({
  projectdata,
  setShow,
  setSelectedAssignees,
  setReloadOutlined,
}: any) => {
  const project = useSelector(PD);
  const user = useSelector(userData);
  const { isLoading, data: projectData } = useGetProjects();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getProjectColor = useCallback(() => {
    const color = ["#35d36c", "#531b64", "#fea60a", "#5c4ac7", "#ff7537"];
    const index = Math.floor(Math.random() * 5);
    return color[index];
  }, []);


  const getProjectsList = useMemo(() => {
    if (projectData?.result && projectData?.result?.length > 0) {
      if (!project?.id) {
        dispatch(
          setProjectData({
            id: projectData.result[0]?.id,
            name: projectData.result[0]?.name,
          })
        );
      }
      return projectData?.result?.map((project: any) => ({
        ...project,
        imagePath:
          project?.imagePath === "?t=" ? undefined : project?.imagePath,
        color: getProjectColor(),
      }));
    }
    return [];
  }, [user, projectData]);

  const [res, setRes] = useState([1, 2, 3, 4, 5]);


  useEffect(() => {
    if (getProjectsList?.length > 0) setRes(getProjectsList);
  }, [getProjectsList]);

  const handleClick = ({
    projectId,
    projectName,
  }: {
    projectId: string;
    projectName: string;
  }) => {
    dispatch(
      setProjectData({
        id: projectId,
        name: projectName,
      })
    );
    navigate("/board");
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1710, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const handleClick2 = () => {
  //   setIsLoading(true);

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // };
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            size: 11,
          },
          Avatar: {
            fontWeightStrong: 600,
            size: 20,
            colorTextPlaceholder: "#EBF1FF",
            colorTextLightSolid: "black",
          },
        },
      }}
    >
      <Row
        className="mb-4 mr-2  Resposivecostom"
        justify="space-between"
        style={{
          backgroundColor: "#fff",
          borderBottom: "none",
          borderRadius: "8px",
          padding: "7px 0px",
        }}
      >
        <Col span={5}>
          <Button type="link" disabled={projectdata?.length <= 3}>
            <Link
              style={{
                fontSize: "14px",
                fontFamily: "Open Sans",
                fontWeight: 400,
                color: " #1677ff",
                textShadow: " 0 0 0.25px currentcolor",
              }}
              to="/viewall"
            >
              View all Projects
              <Button className="custom-badge">
                {projectdata?.length ? projectdata?.length : 0}
              </Button>
            </Link>
          </Button>
        </Col>

        <Col className="mt-1 mr-2">
          <Button
            style={{ height: 27 }}
            onClick={() => {
              setReloadOutlined((prevState: any) => ({
                ...prevState,
              }));
            }}
            icon={
              <ReloadOutlined
                style={{ color: "rgb(61, 153, 37)", fontSize: "14px" }}
              />
            }
          />
        </Col>
      </Row>

      <Col span={24} className="slider-container ">
        <Slider
          className="Resposivecostom Card-Width-one"
          {...settings}
          arrows={res?.length > 3 && !isLoading}
        >
          {res?.map((item: any) => (
         
            
            <>
              {isLoading ? (
                <ProjectCradSkeleton />
              ) : (
                <div>
                  <Card className="main-border mt-1 mb-2 mr-2">
                    <Row align="middle">
                      <Col>
                        <div
                          className="ResponsiveClass"
                          style={{
                            width: "6px",
                            height: "50px",
                            backgroundColor: item?.color,
                            borderRadius: "7px",
                            marginRight: "10px",
                            marginLeft: " 4px",
                          }}
                        />
                      </Col>
                      <Col flex="auto">
                        <Meta
                          avatar={
                            item?.imagePath ? (
                              <Avatar
                                size={45}
                                className="mt-1"
                                shape="square"
                                src={item?.imagePath}
                              />
                            ) : (
                              <Avatar
                                size={40}
                                className="mt-1"
                                shape="square"
                                style={{
                                  border: "1px solid rgb(228, 232, 240)",
                                  backgroundColor: "white",
                                  borderRadius: 10,
                                  fontSize: 14,
                                  cursor: "pointer",
                                }}
                              >
                                <span style={{ color: "#253148" }}>
                                  {item?.alias}
                                </span>
                              </Avatar>
                            )
                          }
                          title={
                            <Row justify="space-between" align="middle">
                              <Col span={19}>
                                <div
                                  style={{
                                    
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleClick({
                                      projectId: item?.id,
                                      projectName: item?.name,
                                    })
                                  }
                                >
                                  <Typography.Paragraph
                                    ellipsis
                                    style={{
                                      fontSize: 15,
                                      fontWeight: "600",
                                      color: "gb(54, 70, 99)",
                                    }}
                                  >
                                    <Tooltip placement="top" title={item?.name}>
                                    
                                     {getEllipsisText(item?.name, 27)}
                                    </Tooltip>
                                  </Typography.Paragraph>
                                  
                                </div>

                                <Button
                                  className="pb-2 dash-link"
                                  type="link"
                                  style={{
                                    color: "rgb(54, 70, 99)",
                                    padding: 0,
                                  }}
                                  onClick={() => {
                                    setShow("Assignees");
                                    setSelectedAssignees(item?.assignee);
                                  }}
                                >
                                  {item?.assignee?.length} Assignee
                                </Button>
                              </Col>
                              <Col span={5}>
                                <Popover
                                  placement="bottom"
                                  title={
                                    <Row
                                      gutter={[0, 4]}
                                      style={{ flexDirection: "column" }}
                                    >
                                      <Col
                                        span={24}
                                        style={{
                                          borderBottom: "1px solid #dfe6e9",
                                          paddingBottom: "8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Row justify="space-between">
                                          <Col
                                            style={{
                                              color: "#3485FF",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                            }}
                                          >
                                            To Do
                                          </Col>
                                          <Col
                                            className="dash-link"
                                            style={{
                                              color: "#333333",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                            }}
                                          >
                                            {item?.todo}
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col
                                        span={24}
                                        style={{
                                          borderBottom: "1px solid #dfe6e9",
                                          paddingBottom: "8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Row justify="space-between">
                                          <Col
                                            style={{
                                              color: "#27B7FF",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                            }}
                                          >
                                            In Progress
                                          </Col>
                                          <Col
                                            className="dash-link"
                                            style={{
                                              color: "#333333",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                            }}
                                          >
                                            {item?.inprogress}
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col
                                        span={24}
                                        style={{
                                          borderBottom: "1px solid #dfe6e9",
                                          paddingBottom: "8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Row justify="space-between">
                                          <Col
                                            style={{
                                              color: "#53D07E",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                            }}
                                          >
                                            Done
                                          </Col>
                                          <Col
                                            className="dash-link"
                                            style={{
                                              color: "#333333",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                            }}
                                          >
                                            {item?.done}
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col span={24}>
                                        <Row align="middle">
                                          <Col>
                                            <img
                                              style={{
                                                height: "16px",
                                                paddingRight: "7.6px",
                                              }}
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/clock.svg"
                                              }
                                              alt="clock"
                                            />
                                          </Col>
                                          <Col>
                                            <span
                                              style={{
                                                color: "#44546F",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                fontFamily: "Open Sans",
                                              }}
                                            >
                                              {dayjs(
                                                item?.createdBy?.date
                                              )?.format("DD MMM YYYY")}
                                            </span>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  }
                                  trigger="click"
                                >
                                  <DownOutlined
                                    style={{
                                      color: "#44546F",
                                      fontSize: "15px",
                                      cursor: "pointer",
                                      height: "35px",
                                      padding: "0px 10px",
                                      backgroundColor: "rgb(246, 246, 246)",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </Popover>
                              </Col>
                            </Row>
                          }
                        />
                      </Col>
                    </Row>
                  </Card>
                </div>
              )}
            </>
          ))}
        </Slider>
      </Col>
    </ConfigProvider>
  );
};

export default ProjectDetail;
