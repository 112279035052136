import { Draggable } from "@hello-pangea/dnd";
import {
  Avatar,
  Button,
  Col,
  ConfigProvider,
  notification,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import {
  STATUS,
  STATUS_ARRAY,
  statusArray,
} from "../../constants/commonConstants";
import {
  CheckCircleOutlined,
  CopyOutlined,
  DoubleLeftOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { formatDatedatetimeyeargforcreatetask } from "../../utils/DateConvertor";
import AssigneeAvatar from "../commoncomponents/AssigneeAvatar";
import "../../common/common.css";
import { useCallback, useState } from "react";
import { projectData } from "../../store/slices/project-slice";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddEditTask from "../../common/AddEditTask";
import { getEllipsisText } from "../../utils/Util";
export const DraggableItemListView = ({
  task,
  index,
  size,
  page,
  showLoading,
  sourceDroppableId,
  onSelectDropDown,
  onClick: propsOnClick,
}: any) => {
  const [isOpen1, setIsOpen1] = useState(false);
  const openModal = useCallback(() => setIsOpen1(true), []);
  const closeModal = useCallback(() => setIsOpen1(false), []);
  const navigate = useNavigate();
  const project = useSelector(projectData);
  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return <DoubleLeftOutlined rotate={270} style={{ color: color }} />;
      case 1:
        color = "#ffd19e";
        return <DoubleLeftOutlined rotate={270} style={{ color: color }} />;
      case 0:
        color = "#d9d9d9";
        return <DoubleLeftOutlined rotate={270} style={{ color: color }} />;
      default:
        return null;
    }
  };

  const handleChange = (v: any) => {
    // Trigger dropdown action
    onSelectDropDown({
      destinationDroppableId: STATUS_ARRAY[v?.value],
      sourceIndex: index,
      sourceDroppableId:
        page === "backlog" ? sourceDroppableId : STATUS_ARRAY[task?.tStatus],
      task: task,
    });

    // Trigger notification for "Done" status
  };
  const iconpriority = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}

            <TagOutlined className="" />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <TagOutlined className="" />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <TagOutlined className="" />
          </span>
        );
      default:
        return null;
    }
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#95a5a6",
            fontSize: 14,
            colorPrimaryHover: "none",
            // controlHeight: 32,
            fontSizeIcon: 10,
            // borderRadius: 4,
            paddingSM: 4,
            paddingXS: 4,
            paddingXXS: 4,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 27,
            controlHeightLG: 27,

            // fontSize: 14,
            // controlHeight: 28,
            // borderRadius: 1,
            algorithm: true,
            fontWeightStrong: 600,
          },
        },
      }}
    >
      <Draggable
        draggableId={String(task?.id)}
        index={index}
        // selectid={selectid}
      >
        {(provided, snapshot) => (
          <div
            className="itemshover "
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
              zIndex: -5,
              ...provided.draggableProps.style,
            }}
          >
            <div
              style={{
                borderRadius: "5px",
                height: "100%",
                padding: 4,
                marginBottom: 10,
                marginTop: 8,
              }}
              className="hoveritems"
            >
              <Row align={"middle"}>
                <Col lg={5} xl={3} md={5}>
                  <Row
                    align={"middle"}
                    justify={"space-between"}
                    gutter={[0, 0]}
                  >
                    <Col className="ml-4 priorityHide">
                      {iconpriority(task?.priority)}
                    </Col>
                    <Col lg={16} xl={16} md={15}>
                      <Link to={`/task/${task?.id}`}>
                        <Button
                          size="small"
                          className="resume-btn-sm"
                          style={{
                            color: "rgb(16, 124, 16)",
                            backgroundColor: "rgb(223, 246, 221)",
                            width: "5vw",
                            border: "none",
                            
                          }}
                       
                        >
                       
                         <Tooltip title={task?.uKey} ><Typography.Paragraph style={{  color: "rgb(16, 124, 16)",fontSize:'11px'}} ellipsis>{task?.uKey}</Typography.Paragraph></Tooltip>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={5}
                  md={5}
                  xl={10}
                  onClick={() => {
                    showLoading?.(task?.uKey);
                    propsOnClick(task);
                  }}
                  // onClick={() => {
                  //   if (sourceDroppableId !== "BACKLOG") {
                  //     showLoading?.(task?.uKey);
                  //     propsOnClick(task);
                  //   }
                  // }}
                >
                  <Tooltip
                   
                    title={getEllipsisText(task?.title, 150)}
                    overlayStyle={{
                      whiteSpace: "pre-wrap",
                      
                    }}
                  >
                    <Typography.Paragraph ellipsis>{task?.title}</Typography.Paragraph>
                  </Tooltip>
                </Col>
                <Col lg={4} md={3} xl={4} className="HideBoard">
                  <Select
                    labelInValue
                    className="Board-Backlog taskbord"
                    size="small"
                    options={statusArray}
                    // defaultValue={task?.tStatus}
                    value={task?.tStatus}
                    showSearch
                    filterOption={(input, option: any) =>
                      option?.label
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                    style={{ width: "8vw", cursor: "pointer !important" }}
                    onChange={(e) => handleChange(e)}
                    // disabled={(sourceDroppableId==="BACKLOG")}
                  />
                </Col>
                <Col lg={2} xl={2} md={3} className="HideBoard">
                  <Avatar.Group
                    size={26}
                    max={{
                      count: 2,
                      popover: { trigger: "hover", placement: "bottom" },
                    }}
                    className="cursorPointer"
                  >
                    {task?.assignee?.map((assignee: any, index: any) => (
                      <AssigneeAvatar
                        size={size}
                        key={index}
                        assignee={assignee}
                        index={index}
                        message="Assignee"
                      />
                    ))}
                  </Avatar.Group>
                </Col>
                <Col lg={2} md={3} xl={1} className="cursorPointer HideBoard">
                  {task?.assignedBy?.map((assignee: any, index: any) => (
                    <span style={{ cursor: "pointer" }}>
                      <AssigneeAvatar
                        assignee={assignee}
                        index={index}
                        message="Assignee by"
                      />
                    </span>
                  ))}
                </Col>
                <Col md={2} xl={2} lg={3} className="HideBoard">
                  <Typography.Text ellipsis>
                    {formatDatedatetimeyeargforcreatetask(
                      task?.dueDate,
                      "normal"
                    )}
                  </Typography.Text>
                </Col>

                <Col
                  lg={3}
                  xl={2}
                  style={{ cursor: "pointer" }}
                  className="HideBoard"
                >
                  <Row
                    align={"middle"}
                    justify={"space-between"}
                    gutter={[5, 0]}
                  >
                    <Col>
                      <Typography.Text className="">
                        {priorityIcon(task?.priority)}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <div className="cursorPointer mr-3">
                        <Tooltip title={"Duplicate Task"} className="Moveicon">
                          <CopyOutlined
                            onClick={() => {
                              openModal();
                            }}
                          />
                        </Tooltip>
                      </div>
                    </Col>
                    {/* <Col>
                      <Popover
                        className="cursorPointer"
                        content={"Copied to clipboard"}
                        trigger="click"
                      >
                        <div
                          className="cursorPointer mr-3"
                          onClick={() => {
                            if (page === "board")
                              navigator.clipboard
                                .writeText(
                                  `${process.env.REACT_APP_API_URL}/board/${project?.id}/${task?.UKey}`
                                )
                                .then(() => setIsOpen2(true));
                            // navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/board/${project?.id}/CP-280`).then(() => setIsOpen2(true))
                            else if (page === "backlog")
                              navigator.clipboard
                                .writeText(
                                  `${process.env.REACT_APP_API_URL}/backlog/${project?.id}/${task?.UKey}`
                                )
                                .then(() => setIsOpen2(true));
                          }}
                        >
                          <LinkOutlined
                            onClick={onClick}
                            style={{
                              color: clicked
                                ? "rgb(103, 126, 219) "
                                : "inherit",
                            }}
                          />
                        </div>
                      </Popover>
                    </Col> */}
                  </Row>
                  {isOpen1 && (
                    <AddEditTask
                      onDismiss={() => {
                        closeModal();
                      }}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Draggable>
    </ConfigProvider>
  );
};
