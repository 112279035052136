import React, { useMemo, useState, useEffect } from "react";
import { useGetLogsListDashboard } from "../../../Services/RQDashboardService";
import {
  Avatar,
  Button,
  Card,
  ConfigProvider,
  Table,
  Tooltip,
  Typography,
} from "antd";
import BooksService from "../../../Services/Books";
import {
  getEllipsisText,
  statusbackgroundfont,
  statuscolorfont,
} from "../../../utils/Util";
import { UserAddOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import "../../../../src/common/common.css";

import { userData } from "../../../store/slices/user-slice";
import { useSelector } from "react-redux";
import {
  projectData as PD,
  setProjectData,
} from "../../../store/slices/project-slice";
import { useDispatch } from "react-redux";
import ActivityMessageCradSkeleton from "../Common/ActivityMessageCradSkeleton";
import { AppRoutesPath } from "../../../Routes/AppRoutesPath";
interface ActiviyLogsProps {
  page?: string;
  y?: string | number;
  params?: any;
}

const ActiviyLogsDashboard: React.FC<ActiviyLogsProps> = ({
  page,
  y,
  params,
}) => {
  const user = useSelector(userData);
  const project = useSelector(PD);
  const { isLoading, data: logData } = useGetLogsListDashboard(
    params,
    user?.id
  );
  const [projectList, setProjectList] = useState<any[]>([]);
  const [showFullText, setShowFullText] = useState(false);
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await BooksService.getProjectByUser(user?.id);
        setProjectList(res.result);
      } catch (err) {
        console.error("Failed to fetch projects:", err);
      }
    };
    fetchProjects();
  }, []);

  const getLogsList = useMemo(() => {
    return logData?.result || [];
  }, [logData]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProject = (pid: string) => {
    const project = projectList.find((item) => item?.id === pid);
    return project ? project : "";
  };

  const handleClick = (pId: string) => {
    dispatch(
      setProjectData({
        id: getProject(pId)?.id,
        name: getProject(pId)?.name,
      })
    );
    navigate("/board");
  };

  const sanitizeText = (text: string | undefined, maxLength: number) => {
    if (!text) return "";
    const strippedText = text?.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags
    return strippedText?.length > maxLength
      ? `${strippedText?.substring(0, maxLength)}...`
      : strippedText;
  };
  const columns = useMemo(
    () => [
      {
        key: "logs",
        dataIndex: "logs",
        render: (_: any, item: any) => (
          <Card
            style={{
              border: "none",
            }}
            loading={isLoading}
            className="activiyLogCard"
            title={item?.name}
            size="small"
          >
            <Card.Meta
              avatar={
                item?.thumb ? (
                  <Tooltip
                    title={
                      <img
                        src={item?.thumb}
                        alt="tooltip"
                        style={{ width: "90px" }}
                      />
                    }
                  >
                    <Avatar
                      shape="circle"
                      size={35}
                      style={{ margin: "3px 0px" }}
                      src={item?.thumb}
                    />
                  </Tooltip>
                ) : (
                  <Avatar size={35} icon={<UserAddOutlined />} />
                )
              }
              title={
                <Link
                to={`/${AppRoutesPath?.reports?.home}/${AppRoutesPath?.reports?.userReport}?userid=${item?.assigneeId}`}
                state={{ userid: item?.assigneeId, }}
                >
                  <Typography.Paragraph
                    title={item?.assigneeId}
                    ellipsis
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                   {item?.assigneeName}
                  </Typography.Paragraph>
                </Link>

                // <Link
                //   style={{ cursor: "pointer", color: "#1677ff" }}
                //   to={`/${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`}
                //   state={{ userid: item?.id }}
                // >
                //   {item?.assigneeName}
                // </Link>
              }
              description={
                <div style={{ marginTop: -12 }}>
                  <div style={{ fontSize: "13px", color: "#636e72" }}>
                    {moment(item?.cDate).calendar(null, {
                      sameDay: "[Today at] hh:mm A",
                      nextDay: "[Tomorrow at] hh:mm A",
                      nextWeek: "dddd",
                      lastDay: "[Yesterday at] hh:mm A",
                      lastWeek: "[Last] dddd [at] hh:mm A",
                      sameElse: "MMM DD YYYY, [at] hh:mm A",
                    })}
                  </div>
                </div>
              }
            />

            <div style={{ marginTop: 2 }}>
              <div style={{ borderLeft: "3px solid #C3DFFF" }}>
                <div style={{ marginLeft: 10 }}>
                  {page === "dashboard" ? (
                    <div style={{ display: "flex" }}>
                      <Tooltip title={getProject(item?.pId)?.name}>
                        <Typography.Text
                          ellipsis
                          style={{
                            marginTop: 3,
                            cursor: "pointer",
                            marginBottom: 9,
                            color: "#5770d9",
                            fontSize: 14,
                          }}
                          onClick={() => handleClick(item?.pId)}
                        >
                          {getProject(item?.pId)?.name}
                        </Typography.Text>
                      </Tooltip>

                      <Link to={`/task/${item?.tId}`}>
                        <Button
                          size="small"
                          className="resume-btn-sm mt-1 ml-2"
                          style={{
                            // backgroundColor: "rgb(204, 229, 255)",
                            color: "rgb(16, 124, 16)",
                            backgroundColor: "rgb(223, 246, 221)",
                            width: "100px",
                            border: "none",
                          }}
                        >
                          <Tooltip title={item?.uKey}>
                            <Typography.Text
                              ellipsis
                              style={{
                                color: "rgb(16, 124, 16)",
                                alignItems: "center",
                                fontSize: "11.4px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {item?.uKey}
                            </Typography.Text>
                          </Tooltip>
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <Button
                        size="small"
                        className="resume-btn-sm mt-2 "
                        style={{
                          // backgroundColor: "rgb(204, 229, 255)",
                          color: "rgb(16, 124, 16)",
                          backgroundColor: "rgb(223, 246, 221)",
                          width: "76px",
                          border: "none",
                        }}
                        onClick={() => navigate("/task/" + item?.uKey)}
                      >
                        {item?.uKey}
                      </Button>
                    </div>
                  )}

                  <Tooltip
                    title={
                      <div>
                        {/* Use the sanitized text */}
                        {sanitizeText(item?.remarks, 300)}
                      </div>
                    }
                  >
                    <p
                      style={{
                        fontSize: 11.7,
                        color: "#636e72",

                        marginBottom: 5,
                      }}
                    >
                      {item?.cngType === 4 ? (
                        <>
                          {item?.isDelay ? (
                            <span style={{ fontWeight: "600" }}>
                              {item?.assigneeName} added a task as delay
                            </span>
                          ) : (
                            <span style={{ fontWeight: "600" }}>
                              {item?.assigneeName} added a The comment
                            </span>
                          )}
                          <div>
                            {showFullText ? (
                              <div onClick={() => setShowFullText(true)}>
                                {/* Show truncated text */}
                                {sanitizeText(item?.remarks, 80)}
                              </div>
                            ) : (
                              <div>
                                {/* Use the sanitized text */}
                                {sanitizeText(item?.remarks, 80)}
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <span style={{ fontWeight: "600" }}>
                          {sanitizeText(item?.remarks, 80)}
                        </span>
                      )}
                    </p>
                  </Tooltip>

                  {(item?.cngType === 3 || item?.cngType === 2) && (
                    <div>
                      <Typography.Paragraph ellipsis
                        style={{
                          marginBottom: 27,
                          fontSize: 12,
                          color: "rgb(146, 159, 172)",
                          display: "flex",
                        }}
                      >
                        <Tooltip
                          title={
                            <div>{sanitizeText(item?.from ?? "None", 80)}</div>
                          }
                        >
                          <div>
                            <span
                              style={{
                                color: statuscolorfont(item?.from ?? "None"),
                                borderRadius: "3px",
                                fontSize: 12,
                                backgroundColor: statusbackgroundfont(
                                  item?.from ?? "None"
                                ),
                              }}
                            >
                              {item?.from ? (
                                <div>{sanitizeText(item?.from, 16)}</div>
                              ) : (
                                <div>None</div>
                              )}
                            </span>
                          </div>
                        </Tooltip>

                        <span
                          className="pl-1 pr-1"
                          style={{ color: "#636e72" }}
                        >
                          {" -> "}
                        </span>

                        <Tooltip
                          title={
                            <div>{sanitizeText(item?.to ?? "None", 80)}</div>
                          }
                        >
                          <div>
                            <span
                              style={{
                                color: statuscolorfont(item?.to ?? "None"),
                                fontSize: 12,
                                borderRadius: "3px",
                                backgroundColor: statusbackgroundfont(
                                  item?.to ?? "None"
                                ),
                              }}
                            >
                              {item?.to ? (
                                <div>{sanitizeText(item?.to, 20)}</div>
                              ) : (
                                <div>None</div>
                              )}
                            </span>
                          </div>
                        </Tooltip>
                      </Typography.Paragraph>
                    </div>
                  )}

                  {(item?.from || item?.to) && !item?.remarks && (
                    <p
                      style={{
                        marginTop: 0,
                        marginBottom: 10,
                        fontSize: 14,
                        color: "rgb(146, 159, 172)",
                      }}
                    >
                      <Typography.Text ellipsis>
                        {item?.remarks}
                      </Typography.Text>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Card>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, page, navigate, projectList]
  );
  return (
    <div>
      {isLoading ? (
        <ActivityMessageCradSkeleton rows={6} />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                rowHoverBg: "none",
              },
            },
          }}
        >
          <Table
            showHeader={false}
            size="small"
            title={() => (
              <h4
                style={{
                  paddingLeft: "12px",
                  color: "rgb(54, 70, 99)",
                  // fontSize: "12px",
                  fontWeight: "600",
                  marginBottom: "0px",
                }}
              >
                Activity / Message
              </h4>
            )}
            columns={columns}
            dataSource={getLogsList}
            scroll={{ y: "780px" }}
            pagination={false}
            locale={{
              emptyText: (
                <Typography.Title
                  level={4}
                  style={{
                    textAlign: "center",
                    paddingTop: "147px",
                    height: "84vh",
                  }}
                >
                  {/* <NoData message={undefined} /> */}
                  <img
                    style={{ paddingTop: "180px", width: 110 }}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                  {/* <SmileOutlined style={{ fontSize: 20 }} /> */}
                </Typography.Title>
              ),
            }}
          />
        </ConfigProvider>
      )}
    </div>
  );
};

export default React.memo(ActiviyLogsDashboard);
